import type { NextPage } from 'next';

import { Head } from '~/components';

const Custom404: NextPage = () => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex,nofollow" key="robots" />
      </Head>
      <h1>{'404 - Not Found'}</h1>
    </>
  );
};

export default Custom404;
